@import url('https://use.typekit.net/yag8mwm.css');
@import url('https://fonts.googleapis.com/css?family=Asap:400,500,700&display=swap');

@font-face {
  font-family: 'Blanch Caps Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Blanch Caps Regular'),
    url('~@assets/fonts/blanch_caps-webfont.woff') format('woff');
}

// Daily Beet Theme styles
@import '../colors';
@import '../mixins';

.theme--DailyBeet {
  .blanch--font {
    @extend .blanch--font;

    font-weight: 400;
  }

  .header-1 {
    @include font-size(34px, $xs-x: 0.9, $sm-x: 0.8, $md-x: 0.8);

    font-weight: 400;
  }

  .header-2 {
    @include font-size(45px, $xs-x: 0.9, $sm-x: 0.8, $md-x: 0.8);
  }
  .header-3 {
    @include font-size(56px);
  }
  .header-4 {
    @include font-size(91px);
  }

  .header-1,
  .header-2,
  .header-3,
  .header-4 {
    margin-top: 0;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 0.02em;
  }

  .r-headline {
    @include font-size(22px, $xs-x: 0.9, $sm-x: 0.9, $md-x: 1, $lg-x: 1);

    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.015em;
  }
  .r-title {
    @extend .motor--font;
  }
}
